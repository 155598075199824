<template>
  <div class="admin-wrap">
    <a class="btn-new" href="/admin/clients/0"></a>
    <AdminMenu active-item="clients" />
    <div class="admin-main">
      <AdminHeader title="客戶管理" />
      <div class="client-list">
        <div class="site-search site-search--admin">
          <input type="text" id="inputSearch" v-model="keyword">
          <button id="btnSearch" @click="onSearch">搜尋</button>
        </div>
        <table class="admin-table sortable">
          <thead>
            <tr>
              <th>編號</th>
              <th>姓名</th>
              <th>電話</th>
              <th>身分證號</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="patient in filteredList" :key="patient">
              <td>{{patient.id}}</td>
              <td>{{patient.fullName}}</td>
              <td>{{patient.phone}}</td>
              <td>{{patient.idNumber}}</td>
              <td>
                <a class="admin-btn" href="javascript:void(0);" @click="editPatient(patient.id)">編輯</a>
                <a class="admin-btn admin-btn-delete" href="javascript:void(0);" @click="showConfirmDelete(patient.id)">刪除</a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="admin-pagination">
          <button class="admin-pagination__btn-prev" :class="{ 'is-disable': first }" @click="onPrev">上一頁</button>
          <span class="admin-pagination__page">
            <!-- {{page}} -->
            <input type="text" style="width:80px" v-model="page">
          </span>
          <span class="admin-pagination__page">&nbsp;/ {{totalPages}}頁</span>
          <button class="admin-pagination__btn-next" :class="{ 'is-disable': last }" @click="onNext">下一頁</button>
        </div>
      </div>
    </div>
  </div>
  <!--是否確認刪除帳號?-->
  <div class="admin-confirm-ui" :class="{'is-active': isShowConfirmDelete}" id="confirm-delete">
    <div class="admin-confirm-ui__overlay">
      <div class="admin-confirm-ui__inner">
        <div class="admin-confirm-ui__icon">
          <img src="@/assets/images/icon-exclamation.svg" alt="">
        </div>
        <div class="admin-confirm-ui__right">
          <div class="admin-confirm-ui__content">
            <h2>是否確認刪除？</h2>
            <p>客戶相關資料仍會保存於資料庫，但將不再顯示。</p>
          </div>
          <button class="admin-confirm-ui__btn-cancel" @click="hideConfirmDelete()">取消</button>
          <button class="admin-confirm-ui__btn-delete" @click="deletePatient()">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import {
  apiListPatients,
  apiDeletePatient
} from '@/api/v1/admin'

export default {
  name: 'Clients',
  components: { AdminMenu, AdminHeader },
  computed: {
    filteredList () {
      return this.patients.filter(p => (p.fullName.includes(this.keyword) || p.phone.includes(this.keyword) || p.idNumber.includes(this.keyword)))
    }
  },
  created () {
    this.fetchData()
  },
  data () {
    return {
      isShowConfirmDelete: false,
      patients: [],
      selectedPatientId: 0,
      keyword: '',
      total: 0,
      page: 1,
      pageSize: 20,
      totalPages: 0,
      first: true,
      last: true
    }
  },
  watch: {
    page () {
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      const params = {
        page: this.page,
        size: this.pageSize,
        keyword: this.keyword
      }
      apiListPatients(params).then(data => {
        if (!data.error) {
          this.patients = data.content
          this.total = data.totalElements
          this.totalPages = data.totalPages
          this.first = data.first
          this.last = data.last
        }
      })
    },
    onSearch () {
      this.page = 1
      this.fetchData()
    },
    onPrev () {
      this.page--
    },
    onNext () {
      this.page++
    },
    showConfirmDelete (patientId) {
      this.isShowConfirmDelete = true
      this.selectedPatientId = patientId
    },
    hideConfirmDelete () {
      this.isShowConfirmDelete = false
      this.selectedPatientId = 0
    },
    editPatient (patientId) {
      router.push({ name: 'AdminClientDetail', params: { clientId: patientId } })
    },
    deletePatient () {
      console.log(`deleting patient id ${this.selectedPatientId}`)
      apiDeletePatient(this.selectedPatientId).then(data => {
        if (data.error) {
          console.log(data.error.message)
        } else {
          this.fetchData()
        }
        this.hideConfirmDelete()
      })
    }
  }
}
</script>

<style>

</style>
